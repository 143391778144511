var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('content-template',{attrs:{"menuName":((_vm.inputType == 'Edit'
        ? _vm.$t('general.edit')
        : _vm.inputType == 'New'
        ? _vm.$t('general.new')
        : _vm.$t('general.userProfile')) + " " + (_vm.userType == 'PublicUser'
        ? _vm.$t('sidebar.publicUsers')
        : _vm.userType == 'Profile'
        ? ''
        : _vm.userType == 'Stakeholder'
        ? _vm.$t('sidebar.stakeholders')
        : _vm.userType == 'Expert'
        ? _vm.$t('sidebar.experts')
        : _vm.userType)),"backButton":_vm.userType == 'Profile' ? false : true},on:{"backToPage":function($event){return _vm.cancelForm()}},scopedSlots:_vm._u([{key:"form",fn:function(){return [_c('form',[_c('Input',{staticClass:"s-mb-20",attrs:{"label":_vm.$t('general.name'),"error":_vm.user.name.error},model:{value:(_vm.user.name.value),callback:function ($$v) {_vm.$set(_vm.user.name, "value", $$v)},expression:"user.name.value"}}),_c('div',{class:!_vm.isMobile ? 'i-flex-parent' : ''},[_c('div',{class:("" + (!_vm.isMobile ? 'i-flex-child' : ''))},[_c('Input',{staticClass:"s-mb-20",attrs:{"label":"Email","error":_vm.user.email.error},model:{value:(_vm.user.email.value),callback:function ($$v) {_vm.$set(_vm.user.email, "value", $$v)},expression:"user.email.value"}})],1),_c('div',{class:("" + (!_vm.isMobile ? 'i-flex-child s-ml-30' : ''))},[_c('Input',{staticClass:"s-mb-20",attrs:{"label":_vm.$t('general.phoneNumber'),"error":_vm.user.phone_number.error},model:{value:(_vm.user.phone_number.value),callback:function ($$v) {_vm.$set(_vm.user.phone_number, "value", $$v)},expression:"user.phone_number.value"}})],1)]),_c('Input',{staticClass:"s-mb-20",attrs:{"label":((_vm.$t('general.institution')) + " " + (_vm.userType == 'PublicUser' ||
            _vm.userType == 'Admin' ||
            (_vm.role_type == 'public' && _vm.userType == 'Profile') ||
            (_vm.role_type == 'admin' && _vm.userType == 'Profile')
              ? '(Optional)'
              : '')),"error":_vm.user.institution.error},model:{value:(_vm.user.institution.value),callback:function ($$v) {_vm.$set(_vm.user.institution, "value", $$v)},expression:"user.institution.value"}}),_c('Input',{staticClass:"s-mb-20",attrs:{"label":((_vm.$t('general.position')) + " " + (_vm.userType == 'PublicUser' ||
            _vm.userType == 'Admin' ||
            (_vm.role_type == 'public' && _vm.userType == 'Profile') ||
            (_vm.role_type == 'admin' && _vm.userType == 'Profile')
              ? '(Optional)'
              : '')),"error":_vm.user.position.error},model:{value:(_vm.user.position.value),callback:function ($$v) {_vm.$set(_vm.user.position, "value", $$v)},expression:"user.position.value"}}),(
            !(
              _vm.userType == 'Admin' ||
              (_vm.role_type == 'admin' && _vm.userType == 'Profile')
            )
          )?_c('Textarea',{staticClass:"s-mb-20",attrs:{"label":_vm.$t('users.bioLink')},model:{value:(_vm.user.link_bio.value),callback:function ($$v) {_vm.$set(_vm.user.link_bio, "value", $$v)},expression:"user.link_bio.value"}}):_vm._e(),(_vm.userType == 'Expert' || _vm.role_type == 'expert')?_c('div',{staticClass:"s-mb-20"},[_c('label',{staticClass:"s-input-label"},[_vm._v(_vm._s(_vm.$t("sidebar.expertise")))]),_c('Multiselect',{attrs:{"tag-placeholder":"","placeholder":((_vm.$t('general.searchOrAdd')) + " " + (_vm.$t(
              'sidebar.expertise'
            ))),"options":_vm.expertise_dropdown.map(function (type) { return type.id; }),"custom-label":function (opt) { return _vm.expertise_dropdown.find(function (x) { return x.id == opt; }).name; },"multiple":true},model:{value:(_vm.user.expertise_detail.value),callback:function ($$v) {_vm.$set(_vm.user.expertise_detail, "value", $$v)},expression:"user.expertise_detail.value"}}),(_vm.user.expertise_detail.error)?_c('span',{staticClass:"s-input-error"},[_vm._v(" "+_vm._s(_vm.$t("general.fillThisField"))+" ")]):_vm._e()],1):_vm._e(),(_vm.userType == 'Stakeholder' || _vm.role_type == 'stakeholder')?_c('Select',{attrs:{"label":_vm.$t('general.category'),"options":[
            'National Government',
            'Regional Government & District Authorities',
            'Private Sector',
            'Other Institutions',
            'Development Partners' ],"error":_vm.user.category.error},model:{value:(_vm.user.category.value),callback:function ($$v) {_vm.$set(_vm.user.category, "value", $$v)},expression:"user.category.value"}}):_vm._e(),(_vm.userType == 'Expert' || _vm.role_type == 'expert')?_c('div',[_c('p',{staticClass:"s-text-primary s-weight-600"},[_vm._v(" "+_vm._s(_vm.$t("general.upload"))+" CV (PDF) ")]),_c('div',{staticClass:"s-pb-30 s-position-relative"},[(!_vm.fileDoc.name)?_c('div',{staticClass:"s-file-upload s-text-center"},[_c('div',[_c('img',{attrs:{"src":require('@/assets/icons/primary/cloud_upload.png')}}),_c('p',[_vm._v(_vm._s(_vm.$t("general.dragAndDrop")))]),_c('p',[_vm._v(_vm._s(_vm.$t("general.or")))]),_c('Button',[_vm._v(_vm._s(_vm.$t("general.uploadFile")))])],1)]):_vm._e(),(!_vm.fileDoc.name)?_c('input',{ref:"document",staticClass:"s-hide",attrs:{"id":"document","type":"file","accept":"application/pdf","multiple":""},on:{"change":function($event){return _vm.importDataCV('document')}}}):_vm._e(),_c('div',{staticClass:"s-position-relative s-fullWidth s-center-flex"},[(_vm.fileDoc.name)?_c('div',{staticClass:"\n                  s-center-flex\n                  s-mb-16\n                  s-bg-green-40\n                  s-plr-10\n                  s-radius-10\n                  s-fullWidth\n                "},[_c('p',{staticClass:"s-text-primary"},[_vm._v(_vm._s(_vm.fileDoc.name))]),_c('img',{staticClass:"s-ml-auto",staticStyle:{"cursor":"pointer"},attrs:{"src":require('@/assets/icons/close-primary.svg')},on:{"click":function($event){$event.preventDefault();_vm.fileDoc = {}}}})]):_vm._e()])])]):_vm._e(),(
            !(
              _vm.userType == 'Admin' ||
              (_vm.role_type == 'admin' && _vm.userType == 'Profile')
            )
          )?_c('div',[_c('p',{staticClass:"s-text-primary s-weight-600"},[_vm._v(" "+_vm._s(_vm.$t("general.upload"))+" "+_vm._s(_vm.$t("general.profilePicture"))+" (.png, .jpeg, .jpg) ")]),(
              _vm.profilePict.url &&
              (_vm.inputType == 'Edit' || _vm.userType == 'Profile')
            )?_c('div',[_c('img',{staticStyle:{"width":"100px","height":"100px","object-fit":"contain"},attrs:{"src":_vm.profilePict.url,"alt":""}})]):_vm._e(),_c('div',{staticClass:"s-pb-30 s-position-relative"},[(!_vm.profilePict.name)?_c('div',{staticClass:"s-file-upload s-text-center"},[_c('div',[_c('img',{attrs:{"src":require('@/assets/icons/primary/cloud_upload.png')}}),_c('p',[_vm._v(_vm._s(_vm.$t("general.dragAndDrop")))]),_c('p',[_vm._v(_vm._s(_vm.$t("general.or")))]),_c('Button',[_vm._v(_vm._s(_vm.$t("general.uploadFile")))])],1)]):_vm._e(),(!_vm.profilePict.name)?_c('input',{ref:"document2",staticClass:"s-hide",attrs:{"id":"document2","type":"file","accept":"image/png, image/jpg, image/jpeg","multiple":""},on:{"change":function($event){return _vm.importDataProfile('document2')}}}):_vm._e(),_c('div',{staticClass:"s-position-relative s-fullWidth s-center-flex"},[(_vm.profilePict.name)?_c('div',{staticClass:"\n                  s-center-flex\n                  s-mb-16\n                  s-bg-green-40\n                  s-plr-10\n                  s-radius-10\n                  s-fullWidth\n                "},[_c('p',{staticClass:"s-text-primary"},[_vm._v(_vm._s(_vm.profilePict.name))]),_c('img',{staticClass:"s-ml-auto",staticStyle:{"cursor":"pointer"},attrs:{"src":require('@/assets/icons/close-primary.svg')},on:{"click":function($event){$event.preventDefault();_vm.profilePict = {}}}})]):_vm._e()])])]):_vm._e()],1),_c('div',{staticClass:"s-mt-20 s-flex"},[_c('div',{staticClass:"s-ml-auto"},[(_vm.userType == 'Profile' ? false : true)?_c('Button',{staticClass:"s-ml-20",attrs:{"color":"error"},on:{"click":function($event){$event.preventDefault();return _vm.cancelForm()}}},[_vm._v(_vm._s(_vm.$t("general.cancel")))]):_vm._e(),_c('Button',{staticClass:"s-ml-20",attrs:{"isLoading":_vm.isLoading},on:{"click":function($event){$event.preventDefault();return _vm.onFinish()}}},[_vm._v(_vm._s(_vm.$t("general.submit")))])],1)])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }