<template>
  <div>
    <content-template
      :menuName="`${
        inputType == 'Edit'
          ? $t('general.edit')
          : inputType == 'New'
          ? $t('general.new')
          : $t('general.userProfile')
      } ${
        userType == 'PublicUser'
          ? $t('sidebar.publicUsers')
          : userType == 'Profile'
          ? ''
          : userType == 'Stakeholder'
          ? $t('sidebar.stakeholders')
          : userType == 'Expert'
          ? $t('sidebar.experts')
          : userType
      }`"
      :backButton="userType == 'Profile' ? false : true"
      @backToPage="cancelForm()"
    >
      <template #form>
        <form>
          <Input
            v-model="user.name.value"
            :label="$t('general.name')"
            class="s-mb-20"
            :error="user.name.error"
          />
          <div :class="!isMobile ? 'i-flex-parent' : ''">
            <div :class="`${!isMobile ? 'i-flex-child' : ''}`">
              <Input
                v-model="user.email.value"
                label="Email"
                class="s-mb-20"
                :error="user.email.error"
              />
            </div>
            <div :class="`${!isMobile ? 'i-flex-child s-ml-30' : ''}`">
              <Input
                v-model="user.phone_number.value"
                :label="$t('general.phoneNumber')"
                class="s-mb-20"
                :error="user.phone_number.error"
              />
            </div>
          </div>
          <Input
            v-model="user.institution.value"
            :label="`${$t('general.institution')} ${
              userType == 'PublicUser' ||
              userType == 'Admin' ||
              (role_type == 'public' && userType == 'Profile') ||
              (role_type == 'admin' && userType == 'Profile')
                ? '(Optional)'
                : ''
            }`"
            class="s-mb-20"
            :error="user.institution.error"
          />
          <Input
            v-model="user.position.value"
            :label="`${$t('general.position')} ${
              userType == 'PublicUser' ||
              userType == 'Admin' ||
              (role_type == 'public' && userType == 'Profile') ||
              (role_type == 'admin' && userType == 'Profile')
                ? '(Optional)'
                : ''
            }`"
            class="s-mb-20"
            :error="user.position.error"
          />

          <Textarea
            v-if="
              !(
                userType == 'Admin' ||
                (role_type == 'admin' && userType == 'Profile')
              )
            "
            :label="$t('users.bioLink')"
            v-model="user.link_bio.value"
            class="s-mb-20"
          />

          <div
            class="s-mb-20"
            v-if="userType == 'Expert' || role_type == 'expert'"
          >
            <label class="s-input-label">{{ $t("sidebar.expertise") }}</label>
            <Multiselect
              v-model="user.expertise_detail.value"
              tag-placeholder=""
              :placeholder="`${$t('general.searchOrAdd')} ${$t(
                'sidebar.expertise'
              )}`"
              :options="expertise_dropdown.map((type) => type.id)"
              :custom-label="
                (opt) => expertise_dropdown.find((x) => x.id == opt).name
              "
              :multiple="true"
            ></Multiselect>
            <span class="s-input-error" v-if="user.expertise_detail.error">
              {{ $t("general.fillThisField") }}
            </span>
          </div>

          <Select
            v-if="userType == 'Stakeholder' || role_type == 'stakeholder'"
            :label="$t('general.category')"
            :options="[
              'National Government',
              'Regional Government & District Authorities',
              'Private Sector',
              'Other Institutions',
              'Development Partners',
            ]"
            v-model="user.category.value"
            :error="user.category.error"
          />

          <div v-if="userType == 'Expert' || role_type == 'expert'">
            <p class="s-text-primary s-weight-600">
              {{ $t("general.upload") }} CV (PDF)
            </p>

            <div class="s-pb-30 s-position-relative">
              <div v-if="!fileDoc.name" class="s-file-upload s-text-center">
                <div>
                  <img
                    :src="require('@/assets/icons/primary/cloud_upload.png')"
                  />
                  <p>{{ $t("general.dragAndDrop") }}</p>
                  <p>{{ $t("general.or") }}</p>
                  <Button>{{ $t("general.uploadFile") }}</Button>
                </div>
              </div>
              <input
                v-if="!fileDoc.name"
                id="document"
                ref="document"
                class="s-hide"
                type="file"
                @change="importDataCV('document')"
                accept="application/pdf"
                multiple
              />
              <div class="s-position-relative s-fullWidth s-center-flex">
                <div
                  class="
                    s-center-flex
                    s-mb-16
                    s-bg-green-40
                    s-plr-10
                    s-radius-10
                    s-fullWidth
                  "
                  v-if="fileDoc.name"
                >
                  <p class="s-text-primary">{{ fileDoc.name }}</p>
                  <img
                    :src="require('@/assets/icons/close-primary.svg')"
                    class="s-ml-auto"
                    @click.prevent="fileDoc = {}"
                    style="cursor: pointer"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- PROFILE PICT -->
          <div
            v-if="
              !(
                userType == 'Admin' ||
                (role_type == 'admin' && userType == 'Profile')
              )
            "
          >
            <p class="s-text-primary s-weight-600">
              {{ $t("general.upload") }}
              {{ $t("general.profilePicture") }} (.png, .jpeg, .jpg)
            </p>

            <div
              v-if="
                profilePict.url &&
                (inputType == 'Edit' || userType == 'Profile')
              "
            >
              <img
                :src="profilePict.url"
                alt=""
                style="width: 100px; height: 100px; object-fit: contain"
              />
            </div>

            <div class="s-pb-30 s-position-relative">
              <div v-if="!profilePict.name" class="s-file-upload s-text-center">
                <div>
                  <img
                    :src="require('@/assets/icons/primary/cloud_upload.png')"
                  />
                  <p>{{ $t("general.dragAndDrop") }}</p>
                  <p>{{ $t("general.or") }}</p>
                  <Button>{{ $t("general.uploadFile") }}</Button>
                </div>
              </div>
              <input
                v-if="!profilePict.name"
                id="document2"
                ref="document2"
                class="s-hide"
                type="file"
                @change="importDataProfile('document2')"
                accept="image/png, image/jpg, image/jpeg"
                multiple
              />
              <div class="s-position-relative s-fullWidth s-center-flex">
                <div
                  class="
                    s-center-flex
                    s-mb-16
                    s-bg-green-40
                    s-plr-10
                    s-radius-10
                    s-fullWidth
                  "
                  v-if="profilePict.name"
                >
                  <p class="s-text-primary">{{ profilePict.name }}</p>
                  <img
                    :src="require('@/assets/icons/close-primary.svg')"
                    class="s-ml-auto"
                    @click.prevent="profilePict = {}"
                    style="cursor: pointer"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>

        <div class="s-mt-20 s-flex">
          <div class="s-ml-auto">
            <Button
              color="error"
              class="s-ml-20"
              @click.prevent="cancelForm()"
              v-if="userType == 'Profile' ? false : true"
              >{{ $t("general.cancel") }}</Button
            >
            <Button
              class="s-ml-20"
              @click.prevent="onFinish()"
              :isLoading="isLoading"
              >{{ $t("general.submit") }}</Button
            >
          </div>
        </div>
      </template>
    </content-template>
  </div>
</template>

<script>
import ContentTemplate from "./contentTemplate.vue";
import form from "@/mixins/form";
import common from "@/mixins/common";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { ContentTemplate },
  mixins: [form, common],
  props: {
    menuName: String,
    item: Object,
    inputType: String,
    userType: String,
  },
  data() {
    return {
      fileDoc: {},
      profilePict: {},
      isLoading: false,
      user: {
        name: {
          value: "",
          error: false,
        },
        email: {
          value: "",
          error: false,
        },
        category: {
          value: "",
          error: false,
        },
        phone_number: {
          value: "",
          error: false,
        },
        institution: {
          value: "",
          error: false,
        },
        position: {
          value: "",
          error: false,
        },
        link_bio: {
          value: "",
          error: false,
        },
        expertise_detail: {
          value: [],
          error: false,
        },
      },
      empty_field: false,
    };
  },

  computed: {
    isMobile() {
      return this.$store.getters["getIsMobile"];
    },
    ...mapGetters({
      expertise_dropdown: "expertise/getExpertise",
      role_type: "auth/getRole",
      stakeholder_item: "stakeholder/getStakeholder",
      public_item: "public/getPublic",
      expert_item: "expert/getExpert",
      admin_item: "admin/getAdmin",
      user_data: "auth/getUser",
    }),
  },
  mounted() {
    this.initData();
  },
  methods: {
    ...mapActions({
      stakeholder: "stakeholder/postStakeholderData",
      stakeholder_update: "stakeholder/putStakeholderData",
      public: "public/postPublicData",
      public_update: "public/putPublicData",
      admin: "admin/postAdminData",
      admin_update: "admin/putAdminData",
      expert: "expert/postExpertData",
      expert_update: "expert/putExpertData",
      setExpertise: "expertise/setExpertiseDropdownData",
      setStakeholder: "stakeholder/setStakeholderSingleData",
      setPublic: "public/setPublicSingleData",
      setExpert: "expert/setExpertSingleData",
      setAdmin: "admin/setAdminSingleData",
      setUserData: "admin/setAdminSingleData",
      setUser: "auth/setUserData",
    }),

    checkEmptyField(data) {
      var count_true = 0;
      for (const key in data) {
        //traverse through object's keys
        if (
          (data[key].value == "" ||
            data[key].value == [] ||
            data[key].value == null) &&
          key != "link_bio"
        ) {
          if (key == "category") {
            //key category hanya untuk stakeholder
            if (this.userType == "Stakeholder") {
              data[key].error = true;
              this.empty_field = true;
              count_true += 1;
            }
          } else if (key == "expertise_detail") {
            //key expertise detail hanya untuk expert
            if (this.userType == "Expert") {
              data[key].error = true;
              this.empty_field = true;
              count_true += 1;
            }
          } else if (key == "institution" || key == "position") {
            //key institusi dan posisi optional utk public dan admin
            if (
              !(
                this.userType == "PublicUser" ||
                (this.role_type == "public" && this.userType == "Profile") ||
                this.userType == "Admin" ||
                (this.role_type == "admin" && this.userType == "Profile")
              )
            ) {
              data[key].error = true;
              this.empty_field = true;
              count_true += 1;
            }
          } else {
            data[key].error = true;
            this.empty_field = true;
            count_true += 1;
          }
        } else {
          data[key].error = false;
        }

        //file CV
        if (this.fileDoc.name == undefined && this.userType == "Expert") {
          count_true += 1;
          this.empty_field = true;
          this.showSnackbar({
            type: "error",
            text: "Upload the CV!",
          });
        }
        //Profile picture
        if (
          this.profilePict.name == undefined &&
          !(
            this.userType == "Admin" ||
            (this.role_type == "admin" && this.userType == "Profile")
          )
        ) {
          count_true += 1;
          this.empty_field = true;
          this.showSnackbar({
            type: "error",
            text: "Upload the profile picture!",
          });
        }
      }
      if (count_true == 0) this.empty_field = false;
    },

    importDataCV(id) {
      const fileInput = this.$refs[id];
      const fileUpload = fileInput.files[0];
      const fsize = fileUpload.size;
      const file = Math.round(fsize / 1024);

      if (file <= 5000) this.fileDoc = fileUpload;
    },

    importDataProfile(id) {
      const fileInput = this.$refs[id];
      const fileUpload = fileInput.files[0];
      const fsize = fileUpload.size;
      const file = Math.round(fsize / 1024);

      if (file <= 5000) this.profilePict = fileUpload;
    },

    async initData() {
      this.setLoadingPage(true);
      if (this.userType == "Expert") {
        await this.setExpertise();
        if (this.inputType == "Edit") {
          await this.setExpert(this.$route.params.id);
          this.editItem(this.expert_item);

          //filter id expertise detail
          let expertise_temp = new Array();
          this.expert_item.expertises.forEach((element) => {
            expertise_temp.push(element.id);
          });
          this.user.expertise_detail.value = Object.assign([], expertise_temp);
          this.fileDoc.name = this.expert_item.cv_name;
          this.profilePict.url = this.expert_item.profile_url;
          this.profilePict.name = this.expert_item.profile;
        }
      } else if (this.userType == "Stakeholder" && this.inputType == "Edit") {
        await this.setStakeholder(this.$route.params.id);
        this.editItem(this.stakeholder_item);
        this.profilePict.url = this.stakeholder_item.profile_url;
        this.profilePict.name = this.stakeholder_item.profile;
      } else if (this.userType == "PublicUser" && this.inputType == "Edit") {
        await this.setPublic(this.$route.params.id);
        this.editItem(this.public_item);
        this.profilePict.url = this.public_item.profile_url;
        this.profilePict.name = this.public_item.profile;
      } else if (this.userType == "Admin" && this.inputType == "Edit") {
        await this.setAdmin(this.$route.params.id);
        this.editItem(this.admin_item);
      } else if (this.userType == "Profile") {
        await this.setExpertise();
        await this.setUserData();
        if (this.$store.getters["auth/getStatus"] == 1) {
          this.editItem(this.user_data);

          //filter id expertise detail
          let expertise_temp = new Array();
          if (this.role_type == "expert") {
            this.user_data.expertises.forEach((element) => {
              expertise_temp.push(element.id);
            });
            this.user.expertise_detail.value = Object.assign(
              [],
              expertise_temp
            );
          }

          this.profilePict.url = this.user_data.profile_url;
          this.profilePict.name = this.user_data.profile;
        }
      }
      this.setLoadingPage(false);
    },

    tableOrSnackbar(storeName, routerName, message) {
      if (this.$store.getters[`${storeName}/getStatus`] == 1) {
        if (this.userType !== "Profile") {
          this.$router.push(this.$translate({ name: routerName }));
        } else {
          this.showSnackbar({
            type: "success",
            text: "general.editSuccess",
          });
        }
      } else {
        this.showSnackbar({
          type: "error",
          text: `${message}`,
        });
      }
    },

    async onFinish() {
      this.checkEmptyField(this.user);
      if (!this.empty_field) {
        this.isLoading = true;
        //DATA BARU
        if (this.inputType == "New") {
          if (this.userType == "Admin") {
            const data = {
              name: this.user.name.value,
              email: this.user.email.value,
              phone_number: this.user.phone_number.value,
              institution: this.user.institution.value,
              position: this.user.position.value,
              category: this.user.category.value,
            };
            await this.admin(data);
            this.tableOrSnackbar(
              "admin",
              "Admins",
              this.$store.getters["admin/getMessage"]
            );
          } else if (
            this.userType == "Expert" ||
            this.userType == "Stakeholder" ||
            this.userType == "PublicUser"
          ) {
            let formData = new FormData();
            formData.append("name", this.user.name.value);
            formData.append("email", this.user.email.value);
            formData.append("phone_number", this.user.phone_number.value);
            formData.append("institution", this.user.institution.value);
            formData.append("position", this.user.position.value);
            formData.append("file", this.fileDoc);
            formData.append("profile", this.profilePict);
            if (this.user.link_bio.value != null) {
              formData.append("link_bio", this.user.link_bio.value);
            }
            formData.append("category", this.user.category.value);

            for (var i = 0; i < this.user.expertise_detail.value.length; i++) {
              formData.append(
                "expertise_detail_id[]",
                this.user.expertise_detail.value[i]
              );
            }

            if (this.userType == "Stakeholder") {
              await this.stakeholder(formData);
              this.tableOrSnackbar(
                "stakeholder",
                "Stakeholders",
                this.$store.getters["stakeholder/getMessage"]
              );
            } else if (this.userType == "PublicUser") {
              await this.public(formData);
              this.tableOrSnackbar(
                "public",
                "Public Users",
                this.$store.getters["public/getMessage"]
              );
            } else if (this.userType === "Expert") {
              await this.expert(formData);
              this.tableOrSnackbar(
                "expert",
                "Experts",
                this.$store.getters["expert/getMessage"]
              );
            }
          }
          //EDIT DATA
        } else if (this.inputType == "Edit") {
          if (this.userType == "Admin") {
            const data = {
              id: this.$route.params.id,
              name: this.user.name.value,
              email: this.user.email.value,
              phone_number: this.user.phone_number.value,
              institution: this.user.institution.value,
              position: this.user.position.value,
              category: this.user.category.value,
            };

            if (this.userType == "Admin") {
              await this.admin_update(data);
              this.tableOrSnackbar(
                "admin",
                "Admins",
                this.$store.getters["admin/getMessage"]
              );
            }
          } else {
            let formData = new FormData();
            formData.append("id", this.$route.params.id);
            formData.append("name", this.user.name.value);
            formData.append("email", this.user.email.value);
            formData.append("phone_number", this.user.phone_number.value);
            if (this.user.institution.value != null) {
              formData.append("institution", this.user.institution.value);
            }
            if (this.user.position.value != null) {
              formData.append("position", this.user.position.value);
            }
            formData.append("file", this.fileDoc);
            if (this.user.link_bio.value != null) {
              formData.append("link_bio", this.user.link_bio.value);
            }
            formData.append("profile", this.profilePict);
            formData.append("category", this.user.category.value);

            if (this.userType == "Stakeholder") {
              await this.stakeholder_update(formData);
              this.tableOrSnackbar(
                "stakeholder",
                "Stakeholders",
                this.$store.getters["stakeholder/getMessage"]
              );
            } else if (this.userType == "PublicUser") {
              await this.public_update(formData);
              this.tableOrSnackbar(
                "public",
                "Public Users",
                this.$store.getters["public/getMessage"]
              );
            } else if (this.userType == "Expert") {
              for (
                var expertiseId = 0;
                expertiseId < this.user.expertise_detail.value.length;
                expertiseId++
              ) {
                formData.append(
                  "expertise_detail_id[]",
                  this.user.expertise_detail.value[expertiseId]
                );
              }
              await this.expert_update(formData);
              this.tableOrSnackbar(
                "expert",
                "Experts",
                this.$store.getters["expert/getMessage"]
              );
            }
          }
        }

        //USER PROFILE
        if (this.userType == "Profile") {
          if (this.role_type == "admin") {
            const data = {
              id: this.user_data.id,
              name: this.user.name.value,
              email: this.user.email.value,
              phone_number: this.user.phone_number.value,
              institution: this.user.institution.value,
              position: this.user.position.value,
              category: this.user.category.value,
              expertise_detail_id: this.user.expertise_detail.value,
            };
            await this.admin_update(data);
            this.setUser();
            this.tableOrSnackbar(
              "admin",
              null,
              this.$store.getters["admin/getMessage"]
            );
          } else {
            let formData = new FormData();
            formData.append("id", this.user_data.id);
            formData.append("name", this.user.name.value);
            formData.append("email", this.user.email.value);
            formData.append("phone_number", this.user.phone_number.value);
            if (this.user.institution.value != null) {
              formData.append("institution", this.user.institution.value);
            }
            if (this.user.position.value != null) {
              formData.append("position", this.user.position.value);
            }
            formData.append("file", this.fileDoc);
            formData.append("profile", this.profilePict);
            if (this.user.link_bio.value != null) {
              formData.append("link_bio", this.user.link_bio.value);
            }
            formData.append("category", this.user.category.value);

            if (this.role_type == "stakeholder") {
              await this.stakeholder_update(formData);
              this.setUser();
              this.tableOrSnackbar(
                "stakeholder",
                null,
                this.$store.getters["stakeholder/getMessage"]
              );
            } else if (this.role_type == "public") {
              await this.public_update(formData);
              this.setUser();
              this.tableOrSnackbar(
                "public",
                null,
                this.$store.getters["public/getMessage"]
              );
            } else {
              for (
                var expertise = 0;
                expertise < this.user.expertise_detail.value.length;
                expertise++
              ) {
                formData.append(
                  "expertise_detail_id[]",
                  this.user.expertise_detail.value[expertise]
                );
              }

              await this.expert_update(formData);
              this.setUser();
              this.tableOrSnackbar(
                "expert",
                null,
                this.$store.getters["expert/getMessage"]
              );
            }
          }
        }
        this.isLoading = false;
      }
    },
    moveToTable(paramName) {
      this.$router.push(
        this.$translate({
          name: paramName,
        })
      );
    },
    editItem(item) {
      for (const key in this.user) {
        this.user[key].value = item[key]; //traverse through object's keys
      }
    },
    cancelForm() {
      if (this.userType !== "PublicUser") {
        this.$router.push(
          this.$translate({
            name: `${this.userType}s`,
          })
        );
      } else {
        this.$router.push(
          this.$translate({
            name: "Public Users",
          })
        );
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
